import React, {
  FC,
  useCallback,
  useEffect,
  useLayoutEffect,
  useRef,
  useState
} from 'react';
import styled from 'styled-components/macro';

import { DimensionsModel } from '../../models';
import { calculateCanvasDimensions } from './calculateCanvasDimensions';
import { calculateImageOffset } from './calculateImageOffset';
import { scaleImageDimensions } from './scaleImageDimensions';

interface CanvasProps {
  ratio?: number;
  height?: number;
  width?: number;
  imageSrc: string | null;
  padding?: number;
  shadow?: number;
  backgroundColor?: string;
  onCanvasChange: (canvas: HTMLCanvasElement) => void;
}

// golden ratio
const DEFAULT_RATIO = 1.62;
const DEFAULT_HEIGHT = 1080;
const DEFAULT_WIDTH = DEFAULT_HEIGHT * DEFAULT_RATIO;
const DEFAULT_PADDING = 10;
const DEFAULT_SHADOW = 0;
const DEFAULT_BACKGROUND = '#ffffff';

export const Canvas: FC<CanvasProps> = ({
  ratio = DEFAULT_RATIO,
  width = DEFAULT_WIDTH,
  height = DEFAULT_HEIGHT,
  padding = DEFAULT_PADDING,
  shadow = DEFAULT_SHADOW,
  backgroundColor = DEFAULT_BACKGROUND,
  onCanvasChange,
  imageSrc
}) => {
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const imgRef = useRef<HTMLImageElement | null>(null);

  const [context2d, setContext2d] = useState<CanvasRenderingContext2D | null>(
    null
  );

  // we want this to change only after image is actually loaded
  // opposing to imageSrc prop
  const [loadedImageSrc, setLoadedImageSrc] = useState<string | null>(null);

  //  ------------------------------------

  const clearCanvas = useCallback(() => {
    const canvas = canvasRef.current;

    if (canvas === null) return;
    if (context2d === null) return;

    context2d.clearRect(0, 0, canvas.width, canvas.height);
  }, [context2d]);

  const rerenderCanvas = useCallback(() => {
    const canvas = canvasRef.current;
    const image = imgRef.current;

    if (context2d === null) return;
    if (canvas === null) return;
    if (image === null) return;

    const canvasDimensions: DimensionsModel = calculateCanvasDimensions(
      ratio,
      image,
      padding
    );

    const scaledImageDimensions = scaleImageDimensions(
      canvasDimensions,
      image,
      padding
    );

    const imageOffset = calculateImageOffset(
      canvasDimensions,
      scaledImageDimensions,
      padding
    );

    canvas.width = canvasDimensions.width;
    canvas.height = canvasDimensions.height;

    context2d.fillStyle = backgroundColor;
    context2d.fillRect(0, 0, canvasDimensions.width, canvasDimensions.height);

    const shadowOffset = shadow / 3 - 1;

    context2d.shadowOffsetX = shadowOffset < 0 ? 0 : shadowOffset;
    context2d.shadowOffsetY = shadowOffset < 0 ? 0 : shadowOffset * 1.2;
    context2d.shadowBlur = shadow;
    context2d.shadowColor = shadow ? '#1a1a1a' : 'transparent';

    context2d.drawImage(
      image,
      imageOffset.x,
      imageOffset.y,
      scaledImageDimensions.width,
      scaledImageDimensions.height
    );

    context2d.restore();

    onCanvasChange(canvas);
  }, [
    context2d,
    ratio,
    width,
    height,
    padding,
    imageSrc,
    backgroundColor,
    shadow
  ]);

  //  ------------------------------------

  // set canvas context
  useLayoutEffect(() => {
    if (canvasRef.current === null) return;
    setContext2d(canvasRef.current.getContext('2d'));
  }, []);

  // load/unload image
  useEffect(() => {
    if (!imageSrc) {
      imgRef.current = null;
      setLoadedImageSrc(null);
      return;
    }

    const img = new Image();

    img.src = imageSrc;
    img.addEventListener('load', () => {
      imgRef.current = img;
      setLoadedImageSrc(img.src);
    });
  }, [imageSrc]);

  // redraw canvas on other changes
  useEffect(() => {
    clearCanvas();
    rerenderCanvas();
  }, [context2d, loadedImageSrc, ratio, padding, backgroundColor, shadow]);

  //  ------------------------------------

  return <StyledCanvas ref={canvasRef} />;
};

const StyledCanvas = styled.canvas`
  max-width: 100%;
  max-height: 100%;
  box-shadow: 5px 5px 30px var(--color-dropshadow);
  background-color: #d9dde0;
`;
