import { createStyles, Modal } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React, { FC, useCallback, useEffect, useRef, useState } from 'react';
import styled from 'styled-components/macro';

import { useDownloadsIncrement } from '../../services/downloads';
import { renderToImage, saveAs } from '../../services/export';
import { ChromakeyModalCanvas } from './ChromakeyModalCanvas';
import { ChromakeyModalControls } from './ChromakeyModalControls';

interface ChromakeyModalProps {
  imageSrc: string;
  open: boolean;
  onApply: (imageSrc: string) => void;
  onClose: () => void;
}

const useStyles = makeStyles(_ =>
  createStyles({
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center'
    }
  })
);

export const ChromakeyModal: FC<ChromakeyModalProps> = ({
  open,
  imageSrc,
  onApply,
  onClose
}) => {
  const classes = useStyles();
  const canvasRef = useRef<HTMLCanvasElement | null>(null);
  const onCanvasUpdate = useCallback((canvas: HTMLCanvasElement) => {
    canvasRef.current = canvas;
  }, []);
  const [saving, setSaving] = useState<boolean>(false);
  const incrementDownloads = useDownloadsIncrement();

  const onApplyClick = useCallback(() => {
    if (!canvasRef.current) return;
    const canvas = canvasRef.current;
    onApply(renderToImage(canvas, 'png'));
    saveAs(canvas, 'chromakey', 'png');
    incrementDownloads('chromakey');
  }, [canvasRef]);

  useEffect(() => setSaving(false), []);

  return (
    <Modal open={open} onClose={onClose} className={classes.modal}>
      <StyledWrapper>
        <StyledCanvasHolder>
          <ChromakeyModalCanvas
            imageSrc={imageSrc}
            onCanvasUpdate={onCanvasUpdate}
          />
        </StyledCanvasHolder>

        <StyledControlsHolder>
          {!saving && <ChromakeyModalControls onApply={onApplyClick} />}
        </StyledControlsHolder>
      </StyledWrapper>
    </Modal>
  );
};

const StyledWrapper = styled.section`
  margin-bottom: 10%;
  display: flex;
  background: var(--color-bg-light);
  padding: 3rem 4rem;
  border-radius: 1rem;

  :focus {
    outline: none;
  }
`;

const StyledCanvasHolder = styled.div`
  height: 400px;
`;

const StyledControlsHolder = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 20rem;
  margin-left: 3rem;
`;
