import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { FC } from 'react';
import styled, { css } from 'styled-components/macro';

interface ExportFormatControlButtonProps {
  icon: IconProp;
  isSelected: boolean;
  onClick: () => void;
}

export const ExportControlButton: FC<ExportFormatControlButtonProps> = ({
  isSelected,
  icon,
  onClick
}) => {
  return (
    <StyledWrapper onClick={onClick} selected={isSelected}>
      <FontAwesomeIcon icon={icon} size={'3x'} />
    </StyledWrapper>
  );
};

const selectedStyle = css`
  color: var(--color-primary-light);
  cursor: default;
`;

const StyledWrapper = styled.div<{ selected: boolean }>`
  transition: color 0.2s ease;
  color: var(--color-grey-light);
  margin-left: 2rem;
  cursor: pointer;
  display: flex;
  width: 3rem;
  height: 3rem;
  align-items: center;
  justify-content: center;

  :hover {
    color: var(--color-primary-light);
  }

  ${p => p.selected && selectedStyle}

  :first-of-type {
    margin-left: 0;
  }
`;
