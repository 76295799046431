import React, {
  FC,
  MouseEventHandler,
  useCallback,
  useContext,
  useEffect,
  useLayoutEffect,
  useRef,
  useState
} from 'react';
import styled, { css } from 'styled-components/macro';

import { AppContext } from '../../context';
import { rgbToHexColorString } from '../Canvas/rgbToHex';
import { applyChromakey } from './applyChromakey';

interface ChromakeyCanvasProps {
  imageSrc: string;
  onCanvasUpdate: (canvas: HTMLCanvasElement) => void;
}

export const ChromakeyModalCanvas: FC<ChromakeyCanvasProps> = ({
  imageSrc,
  onCanvasUpdate
}) => {
  const {
    state: { eyeDropper, chromakey },
    dispatch
  } = useContext(AppContext);

  const canvasRef = useRef<HTMLCanvasElement>(null);
  const imgRef = useRef<HTMLImageElement | null>(null);

  const [context2d, setContext2d] = useState<CanvasRenderingContext2D | null>(
    null
  );

  // we want this to change only after image is actually loaded
  // opposing to imageSrc prop
  const [loadedImageSrc, setLoadedImageSrc] = useState<string | null>(null);

  //  ------------------------------------

  const clearCanvas = useCallback(() => {
    const canvas = canvasRef.current;

    if (canvas === null) return;
    if (context2d === null) return;

    context2d.clearRect(0, 0, canvas.width, canvas.height);
  }, [context2d]);

  const rerenderCanvas = useCallback(() => {
    const canvas = canvasRef.current;
    const image = imgRef.current;

    if (context2d === null) return;
    if (canvas === null) return;
    if (image === null) return;

    canvas.width = image.width;
    canvas.height = image.height;

    context2d.drawImage(image, 0, 0);

    const imageData: ImageData = context2d.getImageData(
      0,
      0,
      canvas.width,
      canvas.height
    );

    context2d.putImageData(applyChromakey(canvas, imageData, chromakey), 0, 0);

    context2d.restore();

    // eslint-disable-next-line no-console
    console.log('rendering chromakey canvas');

    onCanvasUpdate(canvas);
  }, [context2d, chromakey.color, chromakey.tolerance, imageSrc]);

  //  ------------------------------------

  // set canvas context
  useLayoutEffect(() => {
    if (canvasRef.current === null) return;
    setContext2d(canvasRef.current.getContext('2d'));
  }, []);

  // load/unload image
  useEffect(() => {
    if (!imageSrc) {
      imgRef.current = null;
      setLoadedImageSrc(null);
      return;
    }

    const img = new Image();

    img.src = imageSrc;
    img.addEventListener('load', () => {
      imgRef.current = img;
      setLoadedImageSrc(img.src);
    });
  }, [imageSrc]);

  // redraw canvas on other changes
  useEffect(() => {
    clearCanvas();
    rerenderCanvas();
  }, [context2d, chromakey.color, chromakey.tolerance, loadedImageSrc]);

  //  ------------------------------------

  const onCanvasHover = useCallback<MouseEventHandler>(
    event => {
      if (!canvasRef.current) return;
      if (!imgRef.current) return;
      if (!context2d) return;

      // ------------------------------------
      // we want to inform hovered color when eye dropper is active
      // ------------------------------------

      if (!eyeDropper.active) return;

      const {
        x,
        y,
        width: realWidth
      } = canvasRef.current.getClientRects().item(0) as DOMRect;

      // canvas.width & canvas.height set in pixels may be different from
      // the size it takes on the page due to CSS.
      const scale = realWidth / imgRef.current.width;

      const pixelX = Math.round((event.clientX - x) / scale);
      const pixelY = Math.round((event.clientY - y) / scale);

      // pixel color data
      const [r, g, b] = context2d
        .getImageData(pixelX, pixelY, 1, 1)
        .data.values();

      // report color
      dispatch({
        type: 'SetEyeDropperColor',
        payload: rgbToHexColorString(r, g, b)
      });
    },
    [eyeDropper.active, canvasRef, dispatch, context2d, imgRef]
  );

  const onCanvasClick = useCallback<MouseEventHandler>(
    _ => {
      if (!eyeDropper.active) return;
      // ------------------------------------
      // we want to turn of the eye dropper when the color is selected
      // ------------------------------------
      dispatch({ type: 'DeactivateEyeDropper' });
    },
    [eyeDropper.active, dispatch]
  );

  //  ------------------------------------

  return (
    <StyledCanvas
      ref={canvasRef}
      onMouseMove={onCanvasHover}
      onClick={onCanvasClick}
      eyeDropperActive={eyeDropper.active}
    />
  );
};

interface StyledCanvasProps {
  eyeDropperActive: boolean;
}

const eyeDropperActiveCSS = css`
  cursor: pointer;
`;

const StyledCanvas = styled.canvas<StyledCanvasProps>`
  max-width: 100%;
  max-height: 100%;
  box-shadow: 5px 5px 30px var(--color-dropshadow);
  background-color: transparent;

  ${p => p.eyeDropperActive && eyeDropperActiveCSS}
`;
