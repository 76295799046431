import {
  faFacebook,
  faInstagram,
  faPinterest,
  faTwitter
} from '@fortawesome/free-brands-svg-icons';

import { DimensionsModel, ExportFormatModel } from '../../models';

export const formattingOptions: ExportFormatModel[] = [
  {
    id: 'facebook',
    title: 'Facebook',
    ratio: 1200 / 630,
    width: 1200,
    height: 630,
    icon: faFacebook
  },
  {
    id: 'pinterest',
    title: 'Pinterest',
    ratio: 1000 / 1500,
    width: 1000,
    height: 1500,
    icon: faPinterest
  },
  {
    id: 'instagram',
    title: 'Instagram',
    ratio: 1,
    width: 1080,
    height: 1080,
    icon: faInstagram
  },
  {
    id: 'twitter',
    title: 'Twitter',
    ratio: 1200 / 675,
    width: 1200,
    height: 675,
    icon: faTwitter
  }
];

export const getFormattingOptionById = (
  id: string | null
): ExportFormatModel | undefined => {
  return formattingOptions.find(item => item.id === id);
};

export const getRatioByFormattingOptionId = (
  id: string | null
): number | undefined => {
  return getFormattingOptionById(id)?.ratio;
};

export const getDimensionsByFormattingOptionId = (
  id: string | null
): DimensionsModel | undefined => {
  return getFormattingOptionById(id);
};
