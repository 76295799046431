import React, { FC, useCallback, useState } from 'react';
import styled from 'styled-components/macro';

import { ColorPreview } from '../ColorPreview';
import { ColorPickerInput } from './ColorPickerInput';
import { ColorPickerPicker } from './ColorPickerPicker';

interface ColorPickerProps {
  color: string;
  onChange: (color: string) => void;
}

export const ColorPicker: FC<ColorPickerProps> = ({ color, onChange }) => {
  const [visible, setVisible] = useState<boolean>(false);

  const show = useCallback(() => setVisible(true), []);
  const hide = useCallback(() => setVisible(false), []);

  return (
    <StyledWrapper>
      <ColorPreview color={color} onClick={show} />
      <ColorPickerInput color={color} onChange={onChange} />
      {visible && (
        <ColorPickerPicker color={color} onChange={onChange} onDone={hide} />
      )}
    </StyledWrapper>
  );
};

const StyledWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
`;
