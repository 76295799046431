import { createMuiTheme, Slider } from '@material-ui/core';
import { ThemeProvider } from '@material-ui/styles';
import React, { FC, useCallback } from 'react';
import styled from 'styled-components/macro';

import { ColorPicker } from '../ColorPicker';
import { DownloadsCounter } from '../DownloadsCounter';
import { Button, PanelContainer } from '../UIKit';

interface ControlsPanelProps {
  padding: number;
  shadow: number;
  backgroundColor: string;
  onPaddingChange: (padding: number) => void;
  onShadowChange: (padding: number) => void;
  onBackgroundColorChange: (color: string) => void;
  onChromakeyOpen: () => void;
}

const muiTheme = createMuiTheme({
  palette: {
    primary: {
      // TODO: figure out how to use our theme defined
      //       in src/styles/_theme.scss
      main: '#264e85'
    }
  }
});

export const ControlsPanel: FC<ControlsPanelProps> = ({
  onPaddingChange,
  onShadowChange,
  onBackgroundColorChange,
  shadow,
  padding,
  backgroundColor,
  onChromakeyOpen
}) => {
  const handlePaddingChanges = useCallback(
    (_: unknown, value: number | number[]) => {
      // we know it is not an array
      onPaddingChange(value as number);
    },
    []
  );
  const handleShadowChanges = useCallback(
    (_: unknown, value: number | number[]) => {
      // we know it is not an array
      onShadowChange(value as number);
    },
    []
  );

  return (
    <StyledWrapper>
      <ThemeProvider theme={muiTheme}>
        <StyledControlColumn>
          <StyledControlLabel>Canvas Color</StyledControlLabel>
          <ColorPicker
            color={backgroundColor}
            onChange={onBackgroundColorChange}
          />
        </StyledControlColumn>

        <StyledControlColumn>
          <StyledControlLabel>Canvas Padding</StyledControlLabel>
          <Slider
            onChange={handlePaddingChanges}
            defaultValue={20}
            value={padding}
            aria-labelledby="discrete-slider"
            valueLabelDisplay="auto"
            step={10}
            marks
            min={0}
            max={200}
          />
        </StyledControlColumn>

        <StyledControlColumn>
          <StyledControlLabel>Artwork Shadow</StyledControlLabel>
          <Slider
            onChange={handleShadowChanges}
            defaultValue={0}
            value={shadow}
            aria-labelledby="discrete-slider"
            valueLabelDisplay="auto"
            step={3}
            marks
            min={0}
            max={45}
          />
        </StyledControlColumn>

        <StyledControlColumn>
          <Button onClick={onChromakeyOpen}>Chromakey</Button>
        </StyledControlColumn>

        <StyledDownloadsCountHolder>
          <DownloadsCounter />
        </StyledDownloadsCountHolder>
      </ThemeProvider>
    </StyledWrapper>
  );
};

const StyledWrapper = styled(PanelContainer)`
  width: 20rem;
  flex-direction: column;
  z-index: 2;
  padding: 0 2rem;
  justify-content: flex-start;
  font-size: 1.6rem;
  color: var(--color-grey);
`;

const StyledControlColumn = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 2.5rem;

  :last-of-type {
    margin-bottom: 2.5rem;
  }
`;

const StyledControlLabel = styled.span`
  margin-bottom: 0.8rem;
`;

const StyledDownloadsCountHolder = styled.div`
  display: flex;
  align-items: flex-end;
  margin-bottom: 2.5rem;
  justify-content: center;
  flex: auto;
  text-align: center;
  font-size: 1.2rem;
`;
