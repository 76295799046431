import { Slider } from '@material-ui/core';
import React, {
  FC,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState
} from 'react';
import styled from 'styled-components/macro';

import { AppContext } from '../../context';
import { ColorPreview } from '../ColorPreview';
import { Button } from '../UIKit';

interface ControlsPanelChromakeyProps {
  onApply: () => void;
}

export const ChromakeyModalControls: FC<ControlsPanelChromakeyProps> = ({
  onApply
}) => {
  const {
    state: { eyeDropper, chromakey },
    dispatch
  } = useContext(AppContext);

  const timeoutRef = useRef<number | null>(null);

  const [localTolerance, setLocalTolerance] = useState<number>(
    chromakey.tolerance
  );

  const enableEyeDropper = useCallback(
    () => dispatch({ type: 'ActivateEyeDropper' }),
    []
  );

  const handleToleranceChanges = useCallback(
    (_: unknown, value: number | number[]) => {
      setLocalTolerance(value as number);

      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
      timeoutRef.current = setTimeout(() => {
        timeoutRef.current = null;
        dispatch({ type: 'SetChromakeyTolerance', payload: value as number });
      }, 100);
    },
    [setLocalTolerance]
  );

  useEffect(() => {
    setLocalTolerance(chromakey.tolerance);
  }, [chromakey.tolerance]);

  return (
    <StyledWrapper>
      <StyledRow>
        <ColorPreview
          color={eyeDropper.currentColor}
          onClick={enableEyeDropper}
        />

        {!eyeDropper.active && <StyledHint>pick the color</StyledHint>}
        {eyeDropper.active && <StyledHint>click on canvas</StyledHint>}
      </StyledRow>

      <StyledRow>
        <Slider
          value={localTolerance}
          onChange={handleToleranceChanges}
          defaultValue={20}
          aria-labelledby="discrete-slider"
          valueLabelDisplay="auto"
          step={2}
          marks
          min={0}
          max={50}
        />
      </StyledRow>

      <StyledRow>
        <Button onClick={onApply}>Apply</Button>
      </StyledRow>
    </StyledWrapper>
  );
};

const StyledWrapper = styled.div`
  display: flex;
  flex-direction: column;

  .button {
    margin-top: 1rem;
    width: 100%;
    text-align: center;
  }
`;

const StyledRow = styled.div`
  display: flex;
  align-items: center;
`;

const StyledHint = styled.span`
  padding-bottom: 5px;
`;
