import { DimensionsModel } from '../../models';

export const scaleImageDimensions = (
  canvasDimensions: DimensionsModel,
  imageDimensions: DimensionsModel,
  padding: number
): DimensionsModel => {
  const { width: cWidth, height: cHeight } = canvasDimensions;
  const { width: iWidth, height: iHeight } = imageDimensions;

  const cRatio = cWidth / cHeight;
  const iRatio = iWidth / iHeight;

  const doublePadding = padding * 2;

  // canvas is "more landscape" than image
  // the image should be scaled based on heights
  if (cRatio >= iRatio) {
    // eslint-disable-next-line no-console
    console.log('more landscape');
    const scaleRatio = (cHeight - doublePadding) / iHeight;
    return {
      width: iWidth * scaleRatio,
      height: cHeight - doublePadding
    };
  }

  // canvas is "less landscape" than image
  // the image should be scaled based on widths
  // eslint-disable-next-line no-console
  console.log('less landscape');
  const scaleRatio = (cWidth - doublePadding) / iWidth;

  return {
    height: iHeight * scaleRatio,
    width: cWidth - doublePadding
  };
};
