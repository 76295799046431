import { useCallback, useState } from 'react';

import { CONFIG } from '../../config';

export function useSubmitSubscription(): [boolean, (email: string) => void] {
  const [subscribed, setSubscribed] = useState<boolean>(false);
  const subscribe: (email: string) => void = useCallback(
    (email: string) => {
      const data = { email };
      fetch(`${CONFIG.API_URL}/api/subscriptions`, {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(data)
      })
        .then(res => res.json())
        .then(res => {
          if (res.success) setSubscribed(true);
        });
    },
    [setSubscribed]
  );

  return [subscribed, subscribe];
}
