import React, { FC, useCallback, useContext } from 'react';
import styled from 'styled-components/macro';

import { AppContext } from '../../context';
import { ImageLoaderDropZone } from './ImageLoaderDropZone';

interface ImageLoaderProps {
  onImageAdd: (imageData: string) => void;
  loaded: boolean;
}

export const ImageLoader: FC<ImageLoaderProps> = ({ onImageAdd, loaded }) => {
  const {
    state: { eyeDropper }
  } = useContext(AppContext);

  const onAdded = useCallback((files: File[]) => {
    onImageAdd((URL || webkitURL).createObjectURL(files[0]));
  }, []);

  if (eyeDropper.active) return null;

  return (
    <StyledContainer>
      <ImageLoaderDropZone onAdded={onAdded} visible={!loaded} />
    </StyledContainer>
  );
};

const StyledContainer = styled.div`
  flex: auto;
  width: calc(85% - 2rem);
  height: 100%;
  position: absolute;
`;
