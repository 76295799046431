import React, {
  ChangeEventHandler,
  FC,
  useCallback,
  useEffect,
  useState
} from 'react';
import styled from 'styled-components/macro';

const formatColor = (color: string): string => color.replace(/#(.+)/, '$1');
const colorRE = /[0-9a-fA-F]{3}|[0-9a-fA-F]{6}/;
interface ColorPickerInputProps {
  color: string;
  onChange: (color: string) => void;
}

export const ColorPickerInput: FC<ColorPickerInputProps> = ({
  color,
  onChange
}) => {
  const [localValue, setLocalValue] = useState<string>(formatColor(color));

  const handleColorInputChanges = useCallback<
    ChangeEventHandler<HTMLInputElement>
  >(
    event => {
      // we know it is not an array
      const value = event.target.value;

      setLocalValue(value);

      if (!colorRE.test(value)) return;

      onChange(`#${value}`);
    },
    [onChange, color]
  );

  useEffect(() => {
    const formatted = formatColor(color);
    setLocalValue(formatted);
  }, [color]);

  return (
    <StyledWrapper>
      <StyledLabel>#</StyledLabel>
      <StyledInput value={localValue} onChange={handleColorInputChanges} />
    </StyledWrapper>
  );
};

const StyledWrapper = styled.div`
  display: flex;
  align-items: center;
  position: relative;
`;

const StyledLabel = styled.label`
  position: absolute;
  left: 0;
  align-items: center;
  font-size: 2rem;
  padding: 0 0 0.3rem 0;
  color: var(--color-grey-light);
`;

// TODO: refactor
const StyledInput = styled.input`
  font-size: 1.6rem;
  color: var(--color-grey);
  padding: 1rem 1rem 0.9rem 2rem;
  width: 100%;
  min-width: 0;
  margin: 0;
  border: none;
  border-bottom: 1px solid var(--color-grey-light);
  font-family: monospace;
  transition: border-color 0.2s ease;

  :focus {
    outline: none;
    border-color: var(--color-primary);
  }
`;
