import { DimensionsModel } from '../../models';

export const calculateCanvasDimensions = (
  ratio: number,
  imageDimensions: DimensionsModel,
  padding: number
): DimensionsModel => {
  const { width, height } = imageDimensions;
  const imageRatio = width / height;
  const doublePadding = padding * 2;

  // image is more landscape than canvas
  // the pivot dimension is width
  if (imageRatio >= ratio) {
    return {
      width: width + doublePadding,
      height: (width + doublePadding) / ratio
    };
  }

  // image is more portrait than the canvas
  return {
    height: height + doublePadding,
    width: (height + doublePadding) * ratio
  };
};
