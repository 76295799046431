import rgbToHsl from '@alchemyalcove/rgb-to-hsl';
import Color from 'color';

import { ChromakeyState } from '../../context';

export const applyChromakey = (
  canvas: HTMLCanvasElement,
  imageData: ImageData,
  chromakey: ChromakeyState
): ImageData => {
  const { tolerance } = chromakey;
  const { data } = imageData;

  const toleranceH = tolerance * 0.8;
  const toleranceS = tolerance * 1.1;
  const toleranceL = tolerance * 1.2;

  const [keyH, keyS, keyL] = Color(chromakey.color).hsl().array();

  const maxN = data.length;

  for (let i = 0, n = maxN; i < n; i += 4) {
    const r = data[i];
    const g = data[i + 1];
    const b = data[i + 2];

    const [nH, nS, nL] = rgbToHsl([r, g, b]);

    const diffH = Math.abs(nH - keyH);
    const diffS = Math.abs(nS - keyS);
    const diffL = Math.abs(nL - keyL);

    if (diffH < toleranceH && diffS < toleranceS && diffL < toleranceL) {
      addOpacity(data, i, 255);

      // also increase nearest pixels opacity
      addOpacity(data, i - 4, 155);
      addOpacity(data, i + 4, 155);
      addOpacity(data, i - 8, 100);
      addOpacity(data, i + 8, 100);
    }
  }

  return imageData;
};

function addOpacity(data: Uint8ClampedArray, i: number, value: number): void {
  const newValue = data[i + 3] - value;
  data[i + 3] = newValue > 0 ? newValue : 0;
}
