import './styles';

import React, { FC, useCallback, useMemo, useState } from 'react';
import styled from 'styled-components/macro';

import { PageBackground } from './components/Background';
import { Canvas } from './components/Canvas';
import { ChromakeyModal } from './components/ChromakeyModal';
import { ControlsPanel } from './components/ControlsPanel';
import { ExportControl } from './components/ExportControl';
import { Header } from './components/Header';
import { ImageLoader } from './components/ImageLoader';
import { SubscribeForUpdates } from './components/SubscribeForUpdates';
import { ExportFormatModel } from './models';
import { useDownloadsIncrement } from './services/downloads';
import { saveAs } from './services/export';
import {
  formattingOptions,
  getFormattingOptionById
} from './services/formatting';

const App: FC = () => {
  const [selectedId, setSelectedId] = useState<string | null>('instagram');
  const [canvas, setCanvas] = useState<HTMLCanvasElement | null>(null);
  const [imageSrc, setImageSrc] = useState<string | null>(null);
  const [padding, setPadding] = useState<number>(20);
  const [shadow, setShadow] = useState<number>(0);
  const [backgroundColor, setBackgroundColor] = useState<string>('#ffffff');
  const [chromakeyOpen, setChromakeyOpen] = useState<boolean>(false);
  const incrementDownloads = useDownloadsIncrement();

  const selectedItem = useMemo<ExportFormatModel | undefined>(
    () => getFormattingOptionById(selectedId),
    [selectedId]
  );

  const openChromakey = useCallback(() => setChromakeyOpen(true), [
    setChromakeyOpen
  ]);

  const onImageAdd = useCallback(
    (imageDataString: string) => setImageSrc(imageDataString),
    [setImageSrc]
  );

  const onCanvasChange = useCallback(
    (canvas: HTMLCanvasElement) => setCanvas(canvas),
    [setCanvas]
  );

  const onDownload = useCallback(
    (format: 'jpeg' | 'png') => {
      if (canvas === null) return;
      if (selectedId === null) return;
      saveAs(canvas, selectedId, format);
      incrementDownloads(format);
    },
    [canvas, selectedId, incrementDownloads]
  );

  const onChromakeyApply = useCallback(
    imageSrc => {
      setChromakeyOpen(false);
      setImageSrc(imageSrc);
    },
    [canvas, setChromakeyOpen]
  );

  const onChromakeyClose = useCallback(() => {
    setChromakeyOpen(false);
  }, [setChromakeyOpen]);

  return (
    <StyledWrapper className="App">
      <PageBackground />

      <Header />

      <StyledContentRow>
        <StyledCanvasHolder>
          {imageSrc && (
            <Canvas
              imageSrc={imageSrc}
              ratio={selectedItem?.ratio}
              width={selectedItem?.width}
              height={selectedItem?.height}
              padding={padding}
              shadow={shadow}
              backgroundColor={backgroundColor}
              onCanvasChange={onCanvasChange}
            />
          )}

          <ImageLoader onImageAdd={onImageAdd} loaded={!!imageSrc} />
        </StyledCanvasHolder>

        {imageSrc && (
          <StyledControlsPanelHolder>
            <StyledFormatControlHolder>
              <ExportControl
                options={formattingOptions}
                selectedId={selectedId}
                onSelect={setSelectedId}
                onDownload={onDownload}
              />
            </StyledFormatControlHolder>
            <ControlsPanel
              padding={padding}
              shadow={shadow}
              onPaddingChange={setPadding}
              onShadowChange={setShadow}
              backgroundColor={backgroundColor}
              onBackgroundColorChange={setBackgroundColor}
              onChromakeyOpen={openChromakey}
            />
          </StyledControlsPanelHolder>
        )}
      </StyledContentRow>

      {imageSrc && (
        <ChromakeyModal
          imageSrc={imageSrc}
          open={chromakeyOpen}
          onClose={onChromakeyClose}
          onApply={onChromakeyApply}
        />
      )}
      <StyledSubscribeHolder>
        <SubscribeForUpdates />
      </StyledSubscribeHolder>
    </StyledWrapper>
  );
};

export default App;

const StyledWrapper = styled.div`
  display: flex;
  flex-direction: row;
`;

const StyledContentRow = styled.section`
  padding: 0 5%;
  display: flex;
  position: absolute;
  width: 100vw;
  min-height: 400px;
  top: 20%;
  justify-content: flex-end;
`;

const StyledCanvasHolder = styled.div`
  flex-shrink: 0;
  position: relative;
  height: 400px;
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: center;
  flex: auto;
`;

const StyledFormatControlHolder = styled.div`
  padding: 0 3rem;
  display: flex;
`;

const StyledControlsPanelHolder = styled.div`
  display: flex;
`;

const StyledSubscribeHolder = styled.aside`
  position: fixed;
  bottom: 3rem;
  right: 5%;
  z-index: 1;
`;
