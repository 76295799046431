import React, {
  ChangeEventHandler,
  FC,
  KeyboardEventHandler,
  useCallback,
  useState
} from 'react';
import styled from 'styled-components/macro';

import { PanelContainer } from '../UIKit';
import { useSubmitSubscription } from './useSubmitSubscription';

interface SubscribeForUpdatesProps {}

const EMAIL_REGEX = /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

export const SubscribeForUpdates: FC<SubscribeForUpdatesProps> = () => {
  const [subscribed, subscribe] = useSubmitSubscription();
  const [email, setEmail] = useState<string>('');
  const handleChanges = useCallback<ChangeEventHandler<HTMLInputElement>>(
    event => {
      setEmail(event.target.value);
    },
    [setEmail]
  );

  const submit = useCallback(() => {
    if (EMAIL_REGEX.test(email)) subscribe(email);
  }, [email, subscribe]);

  const onKeyPress = useCallback<KeyboardEventHandler<HTMLInputElement>>(
    event => {
      if (event.key.toLocaleLowerCase() === 'enter') submit();
    },
    [submit]
  );

  return (
    <StyledWrapper>
      {subscribed && <span>Thank you!</span>}
      {!subscribed && (
        <StyledLabel>
          <span>Subscribe for Bottega updates</span>
          <StyledRow>
            <StyledInput
              onKeyPress={onKeyPress}
              value={email}
              onChange={handleChanges}
              placeholder={'example@mail.com'}
            />
            <StyledButton
              className={'button'}
              onClick={submit}
              disabled={!EMAIL_REGEX.test(email)}
            >
              Subscribe
            </StyledButton>
          </StyledRow>
        </StyledLabel>
      )}
    </StyledWrapper>
  );
};

const StyledWrapper = styled(PanelContainer)``;

const StyledRow = styled.div`
  display: flex;
  align-items: center;
`;

const StyledLabel = styled.label`
  display: flex;
  flex-direction: column;
  font-size: 1.2rem;
  color: var(--color-text-grey);
  position: relative;
`;

const StyledButton = styled.button`
  border-radius: 0 !important;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;

  :disabled {
    color: var(--color-white) !important;
    background: var(--color-grey-light) !important;
    cursor: default !important;
  }

  :focus {
    outline: none;
  }
`;

// TODO: refactor
const StyledInput = styled.input`
  font-size: 1.6rem;
  color: var(--color-grey);
  padding: 1rem 1rem 0.9rem 0.1rem;
  width: 100%;
  min-width: 0;
  margin: 0;
  border: none;
  border-bottom: 1px solid var(--color-grey-light);
  font-family: monospace;
  transition: border-color 0.2s ease;

  :focus {
    outline: none;
    border-color: var(--color-primary);
  }
`;
