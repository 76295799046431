import React, { FC } from 'react';
import styled from 'styled-components/macro';

export const PageBackground: FC = () => {
  return (
    <StyledWrapper>
      <StyledBackgroundLeft />
      <StyledBackgroundRight />
      <StyledBigTitle>BOTTEGA</StyledBigTitle>
    </StyledWrapper>
  );
};

const StyledWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  z-index: -1;
  overflow: hidden;
`;

const StyledBackgroundLeft = styled.div`
  background: var(--color-bg-dark);
  width: 38%;
  height: 100vh;
`;

const StyledBackgroundRight = styled.div`
  background: var(--color-bg-light);
  flex: auto;
  height: 100vh;
`;

const StyledBigTitle = styled.h1`
  position: absolute;
  font-size: 30rem;
  font-weight: 800;
  letter-spacing: 3rem;
  color: rgb(0 8 21/8%);
  margin: 0;
  padding: 0;
  top: -4%;
  left: -10%;

  @media (max-width: 800px) {
    font-size: 25rem;
    letter-spacing: 2.5rem;
  }
`;
