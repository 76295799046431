import React, { createContext, Dispatch, FC, Reducer, useReducer } from 'react';

export interface EyeDropperState {
  active: boolean;
  currentColor: string;
}

export interface ChromakeyState {
  enabled: boolean;
  color: string;
  tolerance: number;
}

export interface DownloadsState {
  totalCount: number;
}

export interface AppState {
  chromakey: ChromakeyState;
  eyeDropper: EyeDropperState;
  downloads: DownloadsState;
}

export const initialAppState: AppState = {
  downloads: { totalCount: 0 },
  chromakey: {
    enabled: true,
    color: '#ffffff',
    tolerance: 20
  },
  eyeDropper: {
    active: false,
    currentColor: '#ffffff'
  }
};

interface EnableChromakey {
  type: 'EnableChromakey';
  payload: { color: string };
}
interface ApplyChromakey {
  type: 'ApplyChromakey';
}
interface SetChromakeyTolerance {
  type: 'SetChromakeyTolerance';
  payload: number;
}
interface DisableChromakey {
  type: 'DisableChromakey';
}
interface ActivateEyeDropperAction {
  type: 'ActivateEyeDropper';
}
interface DeactivateEyeDropperAction {
  type: 'DeactivateEyeDropper';
}
interface SetEyeDropperColorAction {
  type: 'SetEyeDropperColor';
  payload: string;
}

interface SetDownloadsCount {
  type: 'SetDownloadsCount';
  payload: number;
}

type AppActions =
  | SetDownloadsCount
  | ApplyChromakey
  | EnableChromakey
  | DisableChromakey
  | SetChromakeyTolerance
  | ActivateEyeDropperAction
  | DeactivateEyeDropperAction
  | SetEyeDropperColorAction;

export const AppContext = createContext<{
  state: AppState;
  dispatch: Dispatch<AppActions>;
}>({
  state: initialAppState,
  dispatch: state => state
});

export const AppContextProvider: FC = ({ children }) => {
  const [state, dispatch] = useReducer<Reducer<AppState, AppActions>>(
    (state, action) => {
      switch (action.type) {
        case 'SetDownloadsCount':
          // eslint-disable-next-line no-console
          console.log(action);
          return {
            ...state,
            downloads: {
              ...state.downloads,
              totalCount: action.payload
            }
          };

        case 'ActivateEyeDropper':
          return {
            ...state,
            eyeDropper: {
              ...state.eyeDropper,
              active: true
            }
          };

        case 'DeactivateEyeDropper':
          return {
            ...state,
            chromakey: {
              ...state.chromakey,
              color: state.eyeDropper.currentColor
            },
            eyeDropper: {
              ...state.eyeDropper,
              active: false
            }
          };

        case 'SetEyeDropperColor':
          return {
            ...state,
            eyeDropper: {
              ...state.eyeDropper,
              currentColor: action.payload
            }
          };

        case 'EnableChromakey':
          return {
            ...state,
            chromakey: {
              ...state.chromakey,
              enabled: true,
              color: action.payload.color
            }
          };

        case 'DisableChromakey':
          return {
            ...state,
            chromakey: {
              ...state.chromakey,
              enabled: false
            }
          };

        case 'SetChromakeyTolerance':
          return {
            ...state,
            chromakey: {
              ...state.chromakey,
              tolerance: action.payload
            }
          };
        default:
          break;
      }
      return state;
    },
    initialAppState
  );

  return (
    <AppContext.Provider value={{ state, dispatch }}>
      {children}
    </AppContext.Provider>
  );
};
