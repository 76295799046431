import { faFileDownload } from '@fortawesome/free-solid-svg-icons';
import React, { FC } from 'react';
import styled from 'styled-components/macro';

import { ExportFormatModel } from '../../models';
import { ExportControlButton } from './ExportControlButton';

interface ExportFormatControlProps {
  selectedId?: string | null;
  options: ExportFormatModel[];
  onSelect: (controlId: string) => void;
  onDownload: (format: 'jpeg' | 'png') => void;
}

export const ExportControl: FC<ExportFormatControlProps> = ({
  options,
  selectedId,
  onSelect,
  onDownload
}) => {
  return (
    <StyledWrapper>
      {options.map(item => {
        const isSelected = item.id === selectedId;
        const onSelectFormatClick = (): void => onSelect(item.id);
        const downloadJpeg = (): void => onDownload('jpeg');
        return (
          <StyledControlRow key={item.id}>
            {isSelected && (
              <ExportControlButton
                icon={faFileDownload}
                isSelected={false}
                onClick={downloadJpeg}
              />
            )}

            <ExportControlButton
              icon={item.icon}
              isSelected={isSelected}
              onClick={onSelectFormatClick}
            />
          </StyledControlRow>
        );
      })}
    </StyledWrapper>
  );
};

const StyledWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const StyledControlRow = styled.div`
  display: flex;
  margin-bottom: 2rem;
  justify-content: flex-end;

  :last-of-type {
    margin-bottom: 0;
  }
`;
