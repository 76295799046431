import { useContext, useEffect } from 'react';

import { CONFIG } from '../../config';
import { AppContext } from '../../context';

export const useDownloads = (): number => {
  const {
    state: {
      downloads: { totalCount }
    },
    dispatch
  } = useContext(AppContext);

  useEffect(() => {
    fetch(`${CONFIG.API_URL}/api/downloads`)
      .then(res => res.json())
      .then(json => {
        // TODO: Throw error
        if (json.success !== true) return;
        dispatch({ type: 'SetDownloadsCount', payload: json.data.total });
      });
  }, []);

  return totalCount;
};
