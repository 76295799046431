import { renderToImage } from './renderToImage';

export const saveAs = (
  canvas: HTMLCanvasElement,
  format: string,
  type: 'png' | 'jpeg'
): void => {
  const a = document.createElement('a');

  a.download = `image-${format || 'default'}.${type}`;
  a.href = renderToImage(canvas, type).replace(
    `image/${type}`,
    'image/octet-stream'
  );

  document.body.appendChild(a);

  a.click();
  a.remove();
};
