import { faFileUpload } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { FC, useEffect } from 'react';
import { useDropzone } from 'react-dropzone';
import styled, { css } from 'styled-components/macro';

interface ImageLoaderDropZoneProps {
  onAdded: (files: File[]) => void;
  visible: boolean;
}

export const ImageLoaderDropZone: FC<ImageLoaderDropZoneProps> = ({
  onAdded,
  visible
}) => {
  const {
    acceptedFiles,
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject
  } = useDropzone({
    noKeyboard: true,
    accept: 'image/*'
  });

  useEffect(() => {
    if (acceptedFiles.length) onAdded(acceptedFiles);
  }, [acceptedFiles]);

  return (
    <StyledWrapper {...getRootProps()}>
      <input {...getInputProps()} />

      <StyledDropZone
        isDragActive={isDragActive}
        isDragReject={isDragReject}
        isDragAccept={isDragAccept}
        visible={visible}
      >
        <FontAwesomeIcon icon={faFileUpload} size={'8x'} />
        <span>Drop files here or click to open the file browser</span>
      </StyledDropZone>
    </StyledWrapper>
  );
};

const StyledWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  padding: 2rem;
`;

interface DropZoneProps {
  isDragActive: boolean;
  isDragAccept: boolean;
  isDragReject: boolean;
  visible: boolean;
}

const invisibleCSS = css`
  opacity: 0;
`;

const StyledDropZone = styled.div<DropZoneProps>`
  color: var(--color-grey-light);
  border: 2px dashed var(--color-grey-light);
  padding: 5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  ${p => !p.visible && invisibleCSS}

  span {
    font-size: 1.4rem;
    margin-top: 2rem;
  }
`;
