import React, { FC } from 'react';
import styled from 'styled-components/macro';

interface ButtonProps {
  onClick: () => void;
}

export const Button: FC<ButtonProps> = ({ onClick, children }) => {
  return (
    <StyledWrapper className={'button'} onClick={onClick}>
      {children}
    </StyledWrapper>
  );
};

const StyledWrapper = styled.div``;
