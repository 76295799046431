import React, { FC } from 'react';
import styled from 'styled-components/macro';

export const Header: FC = () => {
  return (
    <StyledWrapper>
      <h1>SHARE ART</h1>
    </StyledWrapper>
  );
};

const StyledWrapper = styled.header`
  flex: auto;
  padding-left: 38%;
  color: var(--color-grey);

  h1 {
    font-size: 4rem;
    font-weight: 200;
    padding: 2rem 3rem;
    margin: 0;
    text-align: left;
  }

  @media (min-width: 900px) {
    h1 {
      font-size: 6rem;
      padding: 3rem 2rem 3rem 4rem;
    }
  }
`;
