import React, { FC, useState } from 'react';
import { ChromePicker, ColorChangeHandler } from 'react-color';
import styled from 'styled-components/macro';

interface ColorPickerPickerProps {
  color: string;
  onChange: (color: string) => void;
  onDone: () => void;
}

export const ColorPickerPicker: FC<ColorPickerPickerProps> = ({
  color,
  onChange,
  onDone
}) => {
  const [localValue, setLocalValue] = useState<string>(color);
  const changeHandler: ColorChangeHandler = value => {
    setLocalValue(value.hex);
    onChange(value.hex);
  };
  return (
    <StyledWrapper>
      <StyledBackdrop onClick={onDone} />
      <ChromePicker color={localValue} onChange={changeHandler} />
    </StyledWrapper>
  );
};

const StyledWrapper = styled.div`
  position: absolute;
  z-index: 3;
  left: -3.2rem;
`;

const StyledBackdrop = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  background: transparent;
`;
