import { useCallback, useContext } from 'react';

import { CONFIG } from '../../config';
import { AppContext } from '../../context';

export const useDownloadsIncrement = (): ((
  type: 'png' | 'jpeg' | 'chromakey'
) => void) => {
  const { dispatch } = useContext(AppContext);

  return useCallback(
    (type: 'png' | 'jpeg' | 'chromakey') => {
      const data = { type };
      fetch(`${CONFIG.API_URL}/api/downloads`, {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(data)
      })
        .then(res => res.json())
        .then(json => {
          // TODO: Throw error
          if (json.success !== true) return;
          // eslint-disable-next-line no-console
          console.log('dispatching update', json.data.total);
          dispatch({ type: 'SetDownloadsCount', payload: json.data.total });
        });
    },
    [dispatch]
  );
};
