import React, { FC } from 'react';
import styled from 'styled-components/macro';

import { useDownloads } from '../../services/downloads';

export const DownloadsCounter: FC = () => {
  const count = useDownloads();
  return <StyledWrapper>Downloaded {count} times</StyledWrapper>;
};

const StyledWrapper = styled.div``;
