import { CoordinatesModel, DimensionsModel } from '../../models';

export const calculateImageOffset = (
  canvasDimensions: DimensionsModel,
  imageDimensions: DimensionsModel,
  padding: number
): CoordinatesModel => {
  const { width: cWidth, height: cHeight } = canvasDimensions;
  const { width: iWidth, height: iHeight } = imageDimensions;

  const cRatio = cWidth / cHeight;
  const iRatio = iWidth / iHeight;

  // canvas is "more landscape" than image
  if (cRatio === iRatio) return { x: padding, y: padding };

  if (cRatio > iRatio) {
    return {
      x: (cWidth - iWidth) / 2,
      y: padding
    };
  }

  // canvas is "less landscape" than image
  return {
    x: padding,
    y: (cHeight - iHeight) / 2
  };
};
