import React, { FC } from 'react';
import styled from 'styled-components/macro';

interface ColorPickerPreviewProps {
  color: string;
  onClick: () => void;
}

export const ColorPreview: FC<ColorPickerPreviewProps> = ({
  color,
  onClick
}) => {
  return (
    <StyledWrapper onClick={onClick}>
      <StyledColorPreview color={color} />
    </StyledWrapper>
  );
};

const StyledWrapper = styled.div`
  padding: 2px;
  box-shadow: 0 0 3px rgb(0 0 0 / 20%);
  background: white;
  margin-right: 1.5rem;
  flex-shrink: 0;
  border-radius: 3px;
  cursor: pointer;
`;

interface StyledColorPreviewProps {
  color: string;
}

const StyledColorPreview = styled.div.attrs<StyledColorPreviewProps>(p => ({
  style: { background: p.color }
}))`
  width: calc(3.2rem - 4px);
  height: calc(3.2rem - 4px);
`;
